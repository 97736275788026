<template>
	<div class="w-full h-full min-h-full">
		<Spinner v-if="loading"></Spinner>
		<div v-else class="flex flex-col w-full h-full relative">
			<Header :config="config" :language="language"></Header>
			<Scene :config="config" class="flex-1"></Scene>

			<div class="mainControls controlsHeight" v-if="!selecting">
				<div
					v-for="category in ['model', 'door', 'body', 'table', 'wall', 'floor']"
					:key="category"
					class="control"
					@click="selecting = category"
				>
					<span class="image" :style="'background-image: url(/images/change-' + category + '.png)'"></span>
					<span class="count">{{ Object.keys(category == 'model' ? config.models : model[pluralize(category)]).length }}</span>
					<span class="category">{{ lang[category] }}</span>
					<span class="value">{{ ucfirst(category == 'model' ? config.defaultModel : model['default' + ucfirst(category)]) }}</span>
				</div>
			</div>

			<div class="content-wrap controlsHeight overflow-y-scroll md:overflow-y-auto overflow-x-hidden" v-if="selecting == 'model'">
				<div class="flex items-center justify-between mt-4 md:mt-8 mb-4 w-full">
					<div class="flex items-center">
						<h1 class="text-xl font-semibold whitespace-nowrap">{{ lang['model'] }}</h1>
						<span class="text-sm mx-2 md:ml-8 mt-0.5 text-center">{{ ucfirst(config.defaultModel) }}</span>
					</div>
					<a href="#" @click.prevent="selectModel(config.defaultModel)" class="flex items-center rounded border hover:border-decodom-800 p-2 md:ml-4">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
							<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
						</svg>

						<span class="ml-2">{{ lang['Uložiť'] }}</span>
					</a>
				</div>

				<div class="flex flex-wrap justify-between md:justify-start -mx-2">
					<span
						v-for="model in Object.keys(config.models)"
						:key="model"
						class="border rounded p-2 mx-2 mb-4 cursor-pointer hover:bg-decodom-800 hover:text-white"
						:class="config.defaultModel == model ? 'bg-decodom-800 text-white' : ''"
						@click="selectModel(model)"
					>{{ ucfirst(model) }}</span>
				</div>
			</div>

			<div class="content-wrap controlsHeight overflow-y-scroll md:overflow-y-auto overflow-x-hidden" v-else-if="selecting">
				<div class="flex items-center justify-between mt-4 md:mt-8 mb-4 w-full">
					<div class="flex items-center">
						<h1 class="text-xl font-semibold whitespace-nowrap">{{ lang[selecting] }}</h1>
						<span class="text-sm mx-2 md:ml-8 mt-0.5 text-center">{{ ucfirst(model['default' + ucfirst(selecting)]) }}</span>
					</div>
					<a href="#" @click.prevent="selectItem(model['default' + ucfirst(selecting)])" class="flex items-center rounded border hover:border-decodom-800 p-2 md:ml-4">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
							<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
						</svg>

						<span class="ml-2">{{ lang['Uložiť'] }}</span>
					</a>
				</div>

				<div class="flex flex-wrap justify-start -mx-2 materialSelection">
					<div
						v-for="item in Object.keys(model[pluralize(selecting)])"
						:key="item"
						class="flex flex-col items-center mb-4 py-2 px-4 cursor-pointer hover:text-decodom-800 material w-1/3 md:w-1/12"
						:class="model['default' + ucfirst(selecting)] == item ? 'text-decodom-800 selected' : ''"
						@click="selectItem(item)"
					>
						<img :src="baseUrl + config.materials[item]" class="rounded-full border-2 w-12 h-12 md:w-16 md:h-16">
						<span class="mt-2 text-center">{{ ucfirst(item) }}</span>
					</div>
				</div>
			</div>

			<div class="cookieBanner" v-if="showCookieBanner">
				<div v-html="config.cookieBannerContent" class="cookieBannerText"></div>
				<div class="cookieBannerChoices">
					<button type="button" @click="cookieBannerDecline" class="border border-decogray-600 px-4 py-1 hover:text-decodom-800 hover:border-decodom-800 transition-all rounded">{{ lang['Zamietnuť'] }}</button>
					<button type="button" @click="cookieBannerAccept" class="bg-decodom-800 text-white border border-decodom-800 px-4 py-1 hover:bg-white hover:text-decodom-800 transition-all rounded">{{ lang['Povoliť'] }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.controlsHeight {
		height: 20rem;

		@screen md {
			height: 16rem;
		}
	}

	.mainControls {
		@apply flex flex-wrap items-center justify-center pt-4 md:pt-8 mx-1;

		.control {
			@apply relative py-4 cursor-pointer flex flex-col items-center w-1/3 md:w-auto md:flex-1;

			.image {
				@apply w-full mb-2 h-8 md:h-16 bg-contain bg-no-repeat bg-center;
				filter: grayscale(100%);
			}

			.count {
				@apply absolute text-white bg-decodom-800 rounded-full px-1 py-0.5 text-xs min-w-5 text-center;
				top: 5%;
				right: 15%;
			}

			.category, .value {
				@apply text-center;
			}

			.category {
				@apply font-semibold md:text-lg
			}

			&:hover {
				.category, .value {
					@apply text-decodom-800;
				}

				.image {
					filter: brightness(60%);
				}
			}
		}
	}

	.materialSelection .material {
		img {
			@apply border-gray-300;
		}

		&:hover img, &.selected img {
			@apply border-decodom-800;
		}
	}

	.cookieBanner {
		@apply border-t flex flex-col md:flex-row items-center py-2 px-4 absolute bottom-0 left-0 right-0 bg-white;

		.cookieBannerText {
			@apply md:flex-1;
		}

		.cookieBannerChoices {
			@apply flex items-center mt-2 md:mt-0;

			button {
				@apply ml-2;
			}
		}
	}
</style>

<style lang="scss">
	.cookieBanner .cookieBannerText a {
		@apply underline whitespace-nowrap;

		&:hover {
			@apply text-decodom-800;
		}
	}
</style>

<script>
import Spinner from './components/Spinner.vue';
import Header from './components/Header.vue';
import Scene from './components/Scene.vue';

let langSk = {
	'Konfigurátor farieb': 'Konfigurátor farieb',

	'model': 'Model',
	'door': 'Predná plocha',
	'body': 'Korpus',
	'table': 'Pracovná doska',
	'wall': 'Stena',
	'floor': 'Podlaha',

	'Uložiť': 'Uložiť',
	'Začať odznova': 'Začať odznova',
	'Začať odznova?': 'Začať odznova?',
	'Odoslať': 'Odoslať',
	'Zatvoriť': 'Zatvoriť',
	'E-mail': 'E-mail',
	'Potvrdiť': 'Potvrdiť',
	'Nápoveda': 'Nápoveda',

	'Vaša kuchyňa vyzerá výborne. Obrázok vám zašleme na zadanú e-mailovú adresu.': 'Vaša kuchyňa vyzerá výborne. Obrázok vám zašleme na zadanú e-mailovú adresu.',
	'Konfiguráciu sme úspešne odoslali.': 'Konfiguráciu sme úspešne odoslali.',
	'Odosielanie zlyhalo. Prosím skúste neskôr.': 'Odosielanie zlyhalo. Prosím skúste neskôr.',
	'Zdieľajte odkaz na vašu kuchyňu:': 'Zdieľajte odkaz na vašu kuchyňu:',
	'Prezrite si detail kuchyne priblížením. Dvojitým ťuknutím navrátite pôvodné zobrazenie.': 'Prezrite si detail kuchyne priblížením. Dvojitým ťuknutím navrátite pôvodné zobrazenie.',

	'Zamietnuť': 'Zamietnuť',
	'Povoliť': 'Povoliť',
};

let langCs = {
	'Konfigurátor farieb': 'Konfigurátor barev',

	'model': 'Model',
	'door': 'Přední plocha',
	'body': 'Korpus',
	'table': 'Pracovní deska',
	'wall': 'Stěna',
	'floor': 'Podlaha',

	'Uložiť': 'Uložit',
	'Začať odznova': 'Začít znovu',
	'Začať odznova?': 'Začít znovu?',
	'Odoslať': 'Odeslat',
	'Zatvoriť': 'Zavřít',
	'E-mail': 'E-mail',
	'Potvrdiť': 'Potvrdit',
	'Nápoveda': 'Nápověda',

	'Vaša kuchyňa vyzerá výborne. Obrázok vám zašleme na zadanú e-mailovú adresu.': 'Vaše kuchyně vypadá výborně. Obrázek vám zašleme na zadanou e-mailovou adresu.',
	'Konfiguráciu sme úspešne odoslali.': 'Konfiguraci jsme úspěšně odeslali.',
	'Odosielanie zlyhalo. Prosím skúste neskôr.': 'Odesílání selhalo. Prosím zkuste později.',
	'Zdieľajte odkaz na vašu kuchyňu:': 'Sdílejte odkaz na vaši kuchyni:',
	'Prezrite si detail kuchyne priblížením. Dvojitým ťuknutím navrátite pôvodné zobrazenie.': 'Prohlédněte si detail kuchyně přiblížením. Dvojitým klepnutím navrátíte původní zobrazení.',

	'Zamietnuť': 'Odmítnout',
	'Povoliť': 'Povolit',
};

export default {
	name: 'App',

	components: {Spinner, Header, Scene},

	data() {
		let cookieBannerChoice = window.localStorage.getItem('cookieBannerChoice');
		let cookieBannerChoiceDateTime = window.localStorage.getItem('cookieBannerChoiceDateTime');

		if (typeof cookieBannerChoiceDateTime !== 'undefined' && cookieBannerChoiceDateTime < new Date().getTime() - 60 * 60 * 24 * 365 * 1000)
			cookieBannerChoice = undefined;

		return {
			loading: true,
			config: null,
			selecting: null,
			popStateListener: null,
			language: {
				sk: langSk,
				cs: langCs,
			},
			cookieBannerChoice: cookieBannerChoice,
		};
	},

	computed: {
		lang() {
			return this.language[this.config.language];
		},

		model() {
			return this.config.models[this.config.defaultModel];
		},

		baseUrl() {
			return '/data/' + window.location.hostname + '/';
		},

		showCookieBanner() {
			return !!this.config.googleTagManagerId && !this.cookieBannerChoice;
		}
	},

	watch: {
		config: {
			handler() {
				if (!this.loading)
					this.pushState();
			},
			deep: true,
		},
	},

	mounted() {
		// load configuration for this domain
		fetch('/data/' + window.location.hostname + '/config.json').then(response => response.json()).then(response => {
			this.config = response;

			let favIcon = document.createElement('link');
			favIcon.rel = 'icon';
			favIcon.href = this.baseUrl + this.config.faviconFileName;
			document.getElementsByTagName('head')[0].appendChild(favIcon);

			window.addEventListener('popstate', this.popStateListener = () => this.recoverState());
			this.recoverState();

			if (this.config.googleTagManagerId && this.cookieBannerChoice === 'accept')
				this.cookieBannerAccept();

			this.loading = false;
		}).catch(() => window.location = 'https://www.decodom.sk');
	},

	beforeDestroy() {
		window.removeEventListener('popstate', this.popStateListener);
	},

	methods: {
		ucfirst(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		pluralize(string) {
			let map = {
				model: 'models',
				door: 'doors',
				body: 'bodies',
				table: 'tables',
				wall: 'walls',
				floor: 'floors',
			};

			return map[string] || '';
		},

		selectModel(model) {
			if (this.config.defaultModel == model)
				this.selecting = null;

			this.config.defaultModel = model;
		},

		selectItem(item) {
			let key = 'default' + this.ucfirst(this.selecting);

			if (this.model[key] == item)
				this.selecting = null;

			this.model[key] = item;
		},

		pushState() {
			let currentParams = new URLSearchParams(window.location.search);
			let newParams = new URLSearchParams(window.location.search);

			newParams.set('model', this.config.defaultModel);

			['door', 'body', 'table', 'wall', 'floor'].forEach(item => newParams.set(item, this.model['default' + this.ucfirst(item)]));

			if (currentParams.toString() === newParams.toString())
				return;

			history.pushState({}, document.title, '?' + newParams.toString());
		},

		recoverState() {
			let searchParams = new URLSearchParams(window.location.search);

			let model = searchParams.get('model');

			if (typeof this.config.models[model] !== 'undefined')
				this.config.defaultModel = model;

			['door', 'body', 'table', 'wall', 'floor'].forEach(item => {
				let value = searchParams.get(item);

				if (typeof this.model[this.pluralize(item)][value] !== 'undefined')
					this.model['default' + this.ucfirst(item)] = value;
			});

			this.selecting = null;
		},

		cookieBannerDecline() {
			if (typeof this.cookieBannerChoice === 'undefined')
				window.localStorage.setItem('cookieBannerChoiceDateTime', (new Date).getTime());

			this.cookieBannerChoice = 'decline';
			window.localStorage.setItem('cookieBannerChoice', 'decline');
		},

		cookieBannerAccept() {
			if (typeof this.cookieBannerChoice === 'undefined')
				window.localStorage.setItem('cookieBannerChoiceDateTime', (new Date).getTime());

			this.cookieBannerChoice = 'accept';
			window.localStorage.setItem('cookieBannerChoice', 'accept');

			window.dataLayer = window.dataLayer || [];
			window.gtag = window.gtag || function() {
				window.dataLayer.push(arguments);
			};

			window.gtag('js', new Date());
			window.gtag('config', this.config.googleTagManagerId);

			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.src = '//www.googletagmanager.com/gtag/js?id=' + this.config.googleTagManagerId;
			document.getElementsByTagName('head')[0].appendChild(script);
		},
	},
}
</script>
