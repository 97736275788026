<template>
	<div>
		<header class="min-h-16 w-full">
			<div class="border-b">
				<div class="min-h-16 flex flex-wrap items-start sm:items-center justify-between content-wrap space-x-4">
					<a :href="config.logoUrl" class="block text-center py-4 sm:py-0">
						<img :src="logoImageUrl" :alt="config.clientName" class="h-8">
						<span class="inline sm:hidden text-decogray-700 text-lg font-semibold whitespace-nowrap h-full">
							{{ lang['Konfigurátor farieb'] }}
						</span>
					</a>

					<div class="hidden sm:flex flex-1 items-center justify-between h-12">
						<span class="text-decogray-700 text-lg font-semibold whitespace-nowrap h-full flex items-center pl-5">
							{{ lang['Konfigurátor farieb'] }}
						</span>
					</div>

					<div class="flex items-center justify-between space-x-4 h-full pt-6 sm:pt-0">
						<a :href="startOverUrl" @click="startOver" class="text-decogray-700 text-lg" :title="lang['Začať odznova']">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
								<path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path>
								<path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path>
							</svg>
						</a>

						<a href="#" @click.prevent="showingHelp = true" class="text-decogray-700 text-lg" :title="lang['Nápoveda']">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
								<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
							</svg>
						</a>

						<a href="#" @click.prevent="sharing = true" class="text-decogray-700 text-lg" :title="lang['Odoslať']">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
								<path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"></path>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</header>

		<div v-if="sharing" class="absolute inset-0 z-50 bg-gray-300 bg-opacity-40 flex items-center justify-center" @click.self="sharing = false; urlCopied = false">
			<div class="bg-white rounded filter drop-shadow-lg max-w-sm">
				<div class="border-b px-4 py-2 flex items-center justify-between">
					<h1 class="text-lg font-semibold mr-2">{{ lang['Odoslať'] }}</h1>
					<a href="#" @click.prevent="sharing = false; urlCopied = false" :title="lang['Zatvoriť']">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"></path>
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"></path>
						</svg>
					</a>
				</div>

				<div class="p-4">
					<p class="mb-4">{{ lang['Vaša kuchyňa vyzerá výborne. Obrázok vám zašleme na zadanú e-mailovú adresu.'] }}</p>

					<div class="flex items-center justify-between">
						<input size="1" @keyup.enter="share" type="text" ref="email" class="emailInput border-2 border-gray-300 focus:border-gray-500 rounded px-2 py-1 outline-none flex-1 mr-4 min-w-0" :placeholder="lang['E-mail']">
						<button type="button" @click="share" ref="shareSubmit" class="bg-decodom-800 text-white border border-decodom-800 px-4 py-1 hover:bg-white hover:text-decodom-800 transition-all rounded">{{ lang['Potvrdiť'] }}</button>
					</div>

					<p class="my-4">{{ lang['Zdieľajte odkaz na vašu kuchyňu:'] }}</p>

					<div class="flex items-center justify-between">
						<input type="text" ref="shareUrl" readonly class="border-2 border-gray-300 bg-gray-200 rounded-tl rounded-bl px-2 py-1 outline-none flex-1 min-w-0" :value="shareUrl" @click.prevent="copyUrl">
						<button type="button" class="border-2 border-l-0 border-gray-300 p-2 hover:text-decodom-800 transition-all rounded-tr rounded-br" @click="copyUrl" :class="urlCopied ? 'text-decodom-800' : ''">
							<svg v-if="!urlCopied" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
								<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
								<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
							</svg>

							<svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
								<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>

		<Spinner fade v-if="sharingInProgress"></Spinner>

		<div v-if="sharingResult" class="absolute inset-0 z-50 bg-gray-300 bg-opacity-40 flex items-center justify-center" @click.self="sharingResult = null">
			<div class="bg-white rounded filter drop-shadow-lg max-w-sm">
				<div class="border-b px-4 py-2 flex items-center justify-between">
					<h1 class="text-lg font-semibold mr-2">{{ lang['Odoslať'] }}</h1>
					<a href="#" @click.prevent="sharingResult = null" :title="lang['Zatvoriť']">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"></path>
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"></path>
						</svg>
					</a>
				</div>

				<div class="p-4">
					<p class="mb-4">{{ sharingResult }}</p>
					<button type="button" @click="sharingResult = null" class="block mx-auto bg-decodom-800 text-white border border-decodom-800 px-4 py-1 hover:bg-white hover:text-decodom-800 transition-all rounded">{{ lang['Zatvoriť'] }}</button>
				</div>
			</div>
		</div>

		<div v-if="showingHelp" class="absolute inset-0 z-50 bg-gray-300 bg-opacity-40 flex items-center justify-center" @click.self="showingHelp = false">
			<div class="bg-white rounded filter drop-shadow-lg max-w-sm">
				<div class="border-b px-4 py-2 flex items-center justify-between">
					<h1 class="text-lg font-semibold mr-2">{{ lang['Nápoveda'] }}</h1>
					<a href="#" @click.prevent="showingHelp = false" :title="lang['Zatvoriť']">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"></path>
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"></path>
						</svg>
					</a>
				</div>

				<div class="p-4">
					<p class="mb-4">{{ lang['Prezrite si detail kuchyne priblížením. Dvojitým ťuknutím navrátite pôvodné zobrazenie.'] }}</p>
					<button type="button" @click="showingHelp = false" class="block mx-auto bg-decodom-800 text-white border border-decodom-800 px-4 py-1 hover:bg-white hover:text-decodom-800 transition-all rounded">{{ lang['Zatvoriť'] }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.emailInput:invalid {
		@apply border-red-500;
	}
</style>

<script>
import Spinner from './Spinner.vue';

export default {
	components: {Spinner},

	props: {
		config: {type: Object, required: true},
		language: {type: Object, required: true},
	},

	data() {
		return {
			sharing: false,
			sharingInProgress: false,
			sharingResult: null,
			urlCopied: false,
			showingHelp: false,
		};
	},

	mounted() {
		document.title = this.lang['Konfigurátor farieb'] + ' | ' + this.config.clientName;
	},

	computed: {
		logoImageUrl() {
			return '/data/' + window.location.hostname + '/' + this.config.logoFileName;
		},

		startOverUrl() {
			return '//' + document.location.host;
		},

		shareUrl() {
			return window.location.toString();
		},

		lang() {
			return this.language[this.config.language];
		},

		model() {
			return this.config.models[this.config.defaultModel];
		},
	},

	methods: {
		startOver(event) {
			if (!confirm(this.lang['Začať odznova?']))
				event.preventDefault();
		},

		share() {
			if (this.sharingInProgress)
				return;

			this.$refs.email.type = "email";
			this.$refs.email.required = true;

			if (!this.$refs.email.reportValidity())
				return;

			this.sharingInProgress = true;
			this.disableSharingForm();

			fetch(this.config.shareApiUrl, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
				domain: window.location.hostname,
				email: this.$refs.email.value,
				model: this.config.defaultModel,
				door: this.model.defaultDoor,
				body: this.model.defaultBody,
				table: this.model.defaultTable,
				wall: this.model.defaultWall,
				floor: this.model.defaultFloor,
			})}).then(() => {
				this.sharingInProgress = false;
				this.sharing = false;
				this.urlCopied = false;
				this.sharingResult = this.lang['Konfiguráciu sme úspešne odoslali.'];
			}).catch(() => {
				this.sharingInProgress = false;
				this.sharing = false;
				this.urlCopied = false;
				this.sharingResult = this.lang['Odosielanie zlyhalo. Prosím skúste neskôr.'];
			});
		},

		copyUrl() {
			this.$refs.shareUrl.select();
			document.execCommand('copy');
			this.urlCopied = true;
		},

		disableSharingForm() {
			this.$refs.email.classList.remove('focus:border-gray-500');
			this.$refs.email.classList.add('filter');
			this.$refs.email.classList.add('opacity-50');
			this.$refs.email.disabled = true;

			this.$refs.shareSubmit.classList.add('filter');
			this.$refs.shareSubmit.classList.add('opacity-50');
			this.$refs.shareSubmit.classList.remove('hover:bg-white');
			this.$refs.shareSubmit.classList.remove('hover:text-decodom-800');
			this.$refs.shareSubmit.disabled = true;
		}
	},
}
</script>
