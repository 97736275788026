<template>
	<div class="scene">
		<div class="relative" ref="model">
			<img :src="modelImage" class="absolute" ref="modelImage" @load="initPanzoom">
			<img :src="bodyImage" class="absolute">
			<img :src="floorImage" class="absolute">
			<img :src="tableImage" class="absolute">
			<img :src="wallImage" class="absolute">
			<img :src="doorImage" class="absolute">
		</div>
	</div>
</template>

<style scoped>
	.scene {
		/*background-image: linear-gradient(135deg, #fafafa 25%, #fcfcfc 25%, #fcfcfc 50%, #fafafa 50%, #fafafa 75%, #fcfcfc 75%, #fcfcfc 100%);
		background-size: 56.57px 56.57px;*/

		@apply relative w-full overflow-hidden outline-none cursor-move bg-gray-50;
		min-height: 16rem;
	}
</style>

<script>
var panzoom = require('panzoom');
import debounce from 'just-debounce-it';

export default {
	props: {
		config: {type: Object, required: true},
	},

	data() {
		return {
			panzoom: null,
			lastTouchDate: null,
			resizeListener: null,
		};
	},

	mounted() {
		window.addEventListener('resize', this.resizeListener = debounce(() => this.resetPanzoom(), 50));
	},

	beforeDestroy() {
		this.panzoom.dispose();
		window.removeEventListener('resize', this.resizeListener);
	},

	computed: {
		baseUrl() {
			return '/data/' + window.location.hostname + '/';
		},

		model() {
			return this.config.models[this.config.defaultModel];
		},

		modelImage() {
			return this.baseUrl + this.model.image;
		},

		doorImage() {
			return this.baseUrl + this.model.doors[this.model.defaultDoor];
		},

		bodyImage() {
			return this.baseUrl + this.model.bodies[this.model.defaultBody];
		},

		tableImage() {
			return this.baseUrl + this.model.tables[this.model.defaultTable];
		},

		wallImage() {
			return this.baseUrl + this.model.walls[this.model.defaultWall];
		},

		floorImage() {
			return this.baseUrl + this.model.floors[this.model.defaultFloor];
		},
	},

	/*watch: {
		config: {
			handler() {
				this.resetPanzoom();
			},
			deep: true,
		},
	},*/

	methods: {
		initPanzoom() {
			if (this.panzoom)
				return;

			this.panzoom = panzoom(this.$refs.model, {
				minZoom: 0.05,
				maxZoom: window.innerWidth <= 768 ? 4 : 2,
				zoomDoubleClickSpeed: 1,

				onDoubleClick: () => this.resetPanzoom(),
				onTouch: (event) => this.handlePanzoomOnTouch(event),
			});

			this.resetPanzoom();

			this.panzoom.on('transform', debounce(() => this.enforcePanzoomBounds(), 1));
		},

		resetPanzoom() {
			// necessary because of library quirks
			this.panzoom.moveTo(0, 0);

			this.panzoom.showRectangle({
				top: 0,
				right: this.$refs.modelImage.width,
				bottom: this.$refs.modelImage.height,
				left: 0,
			});
		},

		enforcePanzoomBounds() {
			let transform = this.panzoom.getTransform();
			let sceneRect = this.$el.getBoundingClientRect();
			let modelImageRect = this.$refs.modelImage.getBoundingClientRect();
			let padding = Math.min(sceneRect.height * 0.1, sceneRect.width * 0.1);

			if (transform.x <= -modelImageRect.width + padding)
				this.panzoom.moveTo(-modelImageRect.width + padding, transform.y);

			if (transform.x >= sceneRect.width - padding)
				this.panzoom.moveTo(sceneRect.width - padding, transform.y);

			if (transform.y <= -modelImageRect.height + padding)
				this.panzoom.moveTo(transform.x, -modelImageRect.height + padding);

			if (transform.y >= sceneRect.height - padding)
				this.panzoom.moveTo(transform.x, sceneRect.height - padding);
		},

		handlePanzoomOnTouch(event) {
			if (event.touches.length !== 1)
				return true;

			let now = new Date;

			if (this.lastTouchDate && now - this.lastTouchDate < 170)
				this.resetPanzoom();

			this.lastTouchDate = now;

			return true;
		},
	},
}
</script>
