<template>
	<div class="spinner-overlay" :class="fade ? 'fade' : ''">
		<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
	</div>
</template>

<script>
export default {
	props: {
		fade: {type: Boolean, required: false, default: false},
	},
}
</script>
